<template>
    <b-row class="align-items-center justify-content-center">
        <div class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5">
            <h1 class="display-4 text-center mb-3 text-capitalize">
                {{ $t("auth.forgot_password") }}
            </h1>
            <p class="text-muted text-center mb-5">{{ $t("auth.zesec") }}</p>

            <b-form @submit.stop.prevent="requestCode">
                <template v-if="step == 0">
                    <b-form-group :label="$t('phoneInput.phoneNumberLabel')">
                        <vue-phone-number-input
                            id="PhoneNumber"
                            class="input-field"
                            v-model="$v.form.PhoneNumber.$model"
                            :error="!validPhone && submitted"
                            @update="validatePhone"
                            :translations="$t('phoneInput')"
                            valid-color="#1e90ff"
                            error-color="#e63857"
                            required
                            fetch-country
                            type="text"
                        />
                        <span id="phoneNumber-feedback" v-if="!validPhone && submitted" style="color: #e63857;  font-size: 0.8125rem">
                            <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                            {{
                                $t("errors.validation_phone_number")
                            }}
                        </span>
                    </b-form-group>
                    <b-button
                        size="lg"
                        class="mb-3"
                        block
                        variant="primary"
                        @click="requestCode"
                        >{{ $t("auth.request_code") }}</b-button
                    >
                </template>
                <template v-if="step == 1">
                    <b-form-group>
                        <b-alert
                            :show="dismissCountDown"
                            variant="light"
                            @dismiss-count-down="countDownChanged"
                        >
                            <p>{{ $t("auth.wait_sms", {sec: dismissCountDown}) }}</p>
                            <b-progress
                                variant="primary"
                                :max="dismissSecs"
                                :value="dismissCountDown"
                                height="4px"
                            ></b-progress>
                            <br/>
                            <b-button
                                size="sm"
                                @click="cancelCode"
                            >
                                {{$t("buttons.cancel")}}
                            </b-button>
                        </b-alert>

                        <b-form-input
                            id="smsCode"
                            class="input-field"
                            autocomplete="off"
                            v-model="$v.formReset.smsCode.$model"
                            :placeholder="$t('auth.sms_code')"
                            :state="validateResetState('smsCode')"
                        />
                        <b-form-invalid-feedback id="smsCode-feedback">
                            <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                            {{ $t("errors.validation_sms_code") }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group>
                        <b-input-group>
                            <b-form-input
                                id="newPassword"
                                class="input-field"
                                autocomplete="off"
                                :placeholder="$t('auth.new_password')"
                                :type="showPassword ? 'text' : 'password'"
                                v-model="$v.formReset.newPassword.$model"
                                :state="validateResetState('newPassword')"
                            />
                            <b-input-group-append>
                                <b-button type="button" @click="showPassword = !showPassword">
                                    <span class="icon is-small is-right"><b-icon :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"/></span>
                                </b-button>
                            </b-input-group-append>
                            <b-form-invalid-feedback id="newPassword-feedback-len" v-if="!$v.formReset.newPassword.minLength || !$v.formReset.newPassword.maxLength">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{ $t("errors.validation_password_length") }}
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback id="newPassword-feedback" v-else>
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{ $t("errors.validation_password") }}
                            </b-form-invalid-feedback>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group>
                        <b-input-group>
                            <b-form-input
                                id="passwordConfirm"
                                class="input-field"
                                :placeholder="$t('auth.confirm_password')"
                                :type="showPassword ? 'text' : 'password'"
                                v-model="$v.formReset.passwordConfirm.$model"
                                :state="validateResetState('passwordConfirm')"
                            />
                            <b-input-group-append>
                                <b-button type="button" @click="showPassword = !showPassword">
                                    <span class="icon is-small is-right"><b-icon :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"/></span>
                                </b-button>
                            </b-input-group-append>
                            <b-form-invalid-feedback id="passwordConfirm-feedback-len" v-if="!$v.formReset.passwordConfirm.minLength || !$v.formReset.passwordConfirm.maxLength">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{ $t("errors.validation_password_length") }}
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback id="passwordConfirm-feedback" v-else>
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{ $t("errors.validation_password_mismatch") }}
                            </b-form-invalid-feedback>
                        </b-input-group>
                    </b-form-group>
                    <b-button
                        size="lg"
                        class="mb-3"
                        block
                        variant="primary"
                        type="button"
                        @click="confirmCode"
                        >{{ $t("auth.save_password") }}</b-button
                    >
                </template>
                <div class="text-center">
                    <small class="text-muted text-center">
                        {{ $t("auth.back") }}
                        <b-link :to="{ name: 'login' }">
                            {{ $t("auth.login") }}
                        </b-link>
                    </small>
                </div>
                <div class="text-center">
                    <small class="text-muted text-center">
                        {{ $t("auth.new") }}
                        <b-link :to="{ name: 'register' }">
                            {{ $t("auth.register") }}
                        </b-link>
                    </small>
                </div>
                <b-row class="mt-3">
                    <b-col v-if="codeError">
                        <b-alert show variant="light">
                            <b-icon
                                icon="exclamation-circle"
                                variant="danger"
                            ></b-icon>
                            {{ codeError }}
                        </b-alert>
                    </b-col>
                </b-row>
            </b-form>
            <language-switcher class="p-0 w-100 mt-3" />
        </div>
        <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
            <div class="bg-cover vh-100 mt-n1 mr-n3 auth-bg"></div>
        </div>
    </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import userController from "@/api/user";
import {
    AUTH_REQUEST_CODE,
    AUTH_SUBMIT_CODE,
    AUTH_RESET_PASSWORD,
    AUTH_ERROR_CLEAR,
} from "@/store/types/auth";

import { USER_PATCH, USER_CHECK_EXIST } from "@/store/types/user";
import { INIT_STARTED, INIT_COMPLETED } from "@/store/types/global";
import { validationMixin } from "vuelidate";
import {
    required,
    numeric,
    sameAs,
    minLength,
    maxLength,
} from "vuelidate/lib/validators";
import VuePhoneNumberInput from "vue-phone-number-input";
import LanguageSwitcher from "@/components/common/LanguageSwitcher";
const stringEntropy = require("fast-password-entropy");

export default {
    mixins: [validationMixin],
    data() {
        return {
            form: {
                PhoneNumber: "",
            },
            formReset: {
                smsCode: "",
                newPassword: "",
                passwordConfirm: "",
            },
            validPhone: false,
            fullPhone: null,
            submitted: false,
            dismissSecs: 60,
            dismissCountDown: 0,
            step: 0,
            codeError: null,
            showPassword: false,
        };
    },
    computed: {
        ...mapGetters(["getAuthErrors", "getUser"]),
    },
    validations: {
        form: {
            PhoneNumber: {
                required,
                phone: function () {
                    return this.validPhone;
                },
            },
        },
        formReset: {
            smsCode: {
                required,
                numeric,
                minLength: minLength(4),
                maxLength: maxLength(4),
            },
            newPassword: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(20),
                entropy: function () {
                    return stringEntropy(this.formReset.newPassword) >= 50;
                },
            },
            passwordConfirm: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(20),
                sameAs: sameAs("newPassword"),
            },
        },
    },
    beforeCreate() {
        this.$nextTick().then(() =>
            document.body.classList.add(
                "d-flex",
                "align-items-center",
                "bg-auth",
                "border-top",
                "border-top-2",
                "border-primary"
            )
        );
    },
    created() {
        this.getAttempt();
    },
    mounted() {
        this.$store.commit(INIT_COMPLETED);
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
            if (dismissCountDown == 0) {
                this.step = 0;
            }
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty && this.submitted ? !$error : null;
        },

        validateResetState(name) {
            const { $dirty, $error } = this.$v.formReset[name];
            return $dirty ? !$error : null;
        },
        validatePhone(phone) {
            this.validPhone = phone.isValid;
            this.fullPhone = phone;
        },
        requestCode() {
            this.codeError = null;
            this.submitted = true;
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            this.$store
                .dispatch(USER_CHECK_EXIST, this.fullPhone.formattedNumber)
                .then((response) => {
                    if (response.data.exists) {
                        this.$store
                            .dispatch(AUTH_REQUEST_CODE, {
                                PhoneNumber: this.fullPhone.formattedNumber,
                            })
                            .then((response) => {
                                this.setAttempt(response);
                            })
                            .catch((error) => {});
                    } else {
                        this.codeError = this.$t("errors.phone_not_exist");
                    }
                });
        },
        confirmCode() {
            this.$v.formReset.$touch();
            if (this.$v.formReset.$anyError) {
                return;
            }

            this.$store
                .dispatch(AUTH_RESET_PASSWORD, {
                    PhoneNumber: this.loadPhone(),
                    SessionToken: this.loadCode(),
                    Code: this.formReset.smsCode,
                    NewPassword: this.formReset.newPassword,
                })
                .then((response) => {
                    this.$store.commit(INIT_STARTED);
                    document.body.classList.remove(
                        "d-flex",
                        "align-items-center",
                        "bg-auth",
                        "border-top",
                        "border-top-2",
                        "border-primary"
                    );
                    this.$router.push({
                        name: "dashboard",
                    });
                    localStorage.removeItem("codeAttempt");
                    localStorage.removeItem("codeAttemptStep");
                })
                .catch((error) => {
                    this.codeError = this.$t("errors.wrong_code");
                });
        },
        cancelCode() {
            localStorage.removeItem("codeAttempt");
            localStorage.removeItem("codeAttemptStep");
            this.step = 0;
        },
        loadCode() {
            let attempt = localStorage.getItem("codeAttempt");
            return JSON.parse(attempt).session;
        },
        loadPhone() {
            let attempt = localStorage.getItem("codeAttempt");
            return JSON.parse(attempt).phone;
        },
        getAttempt() {
            let attempt = localStorage.getItem("codeAttempt");

            if (attempt) {
                attempt = JSON.parse(attempt);

                let range = Math.round((Date.now() - attempt.started) / 1000);
                if (range < this.dismissSecs) {
                    this.step = localStorage.getItem("codeAttemptStep") || 0;
                    this.dismissCountDown = this.dismissSecs - range;
                } else {
                    localStorage.removeItem("codeAttempt");
                    localStorage.removeItem("codeAttemptStep");
                }
            }
        },
        setAttempt(response) {
            let attempt = {
                session: response.result,
                phone: this.fullPhone.formattedNumber,
                started: Date.now(),
            };
            localStorage.setItem("codeAttemptStep", 1);
            this.step = 1;
            this.dismissCountDown = this.dismissSecs;
            localStorage.setItem("codeAttempt", JSON.stringify(attempt));
        },
    },
    components: {
        "vue-phone-number-input": VuePhoneNumberInput,
        "language-switcher": LanguageSwitcher,
    },
    destroyed() {
        this.$store.commit(AUTH_ERROR_CLEAR);
    },
};
</script>
